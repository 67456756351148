<template>
  <div class="studentManage">
    <div class="flex-box">
      <div class="button-box">
        <el-button type="success"
                   icon="el-icon-plus"
                   @click="addStudentFormClick">添加学生
        </el-button>
        <el-button type="danger"
                   icon="el-icon-delete"
                   @click="batchDelete">批量删除
        </el-button>
        <el-button type="primary"
                   icon='el-icon-document'
                   @click="downLoadStudentTemplate">下载学生模板
        </el-button>
        <el-upload action="/api/media/importStudent.do"
                   :headers="uploadHeader"
                   name="uploadFile"
                   :on-success="uploadSuccess"
                   :show-file-list="false">
          <el-button type="primary"
                     icon='el-icon-upload2'>导 入
          </el-button>
        </el-upload>
        <!-- <el-button type="primary"
		  icon='el-icon-download'
		  @click="exportFile">导 出</el-button> -->
        <!-- <el-button type="primary"
		  icon="el-icon-printer">打 印</el-button> -->
      </div>
      <div>
        <el-input class="input-box"
                  placeholder="请输入关键词"
                  v-model="queryStudent"></el-input>
        <el-button type="primary"
                   @click="loadAllStudent(1)">查 询
        </el-button>
      </div>
    </div>
    <div class="select-box">
      <div><span>学年：</span>
        <el-link v-for="term in semesterList"
                 :type="termId===term.id?'success':''"
                 :key="term.id"
                 :underline="false"
                 class="link-box"
                 @click="termClick(term.id)">{{ term.name }}
        </el-link>
      </div>
      <div><span>年级：</span>
        <el-link :underline="false"
                 :type="gradeId===''?'success':''"
                 class="link-box"
                 @click="loadAllStudent(1,1)">全部
        </el-link>
        <el-link v-for="grade in gradeList"
                 :type="gradeId===grade.value?'success':''"
                 :key="grade.value"
                 :underline="false"
                 class="link-box"
                 @click="gradeClick(grade)">{{ grade.label }}
        </el-link>
      </div>
      <div><span>班级：</span>
        <el-link :underline="false"
                 :type="classId===''?'success':''"
                 class="link-box"
                 @click="loadAllStudent(1,2)">全部
        </el-link>
        <el-link v-for="cls in classList"
                 :type="classId===cls.value?'success':''"
                 :key="cls.value"
                 :underline="false"
                 class="link-box"
                 @click="classClick(cls.value)">{{ cls.label }}
        </el-link>
      </div>
    </div>
    <!-- startprint -->
    <el-table ref="studentTable"
              :data="studentTable"
              @selection-change="studentSelectChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="姓名"
                       align="center"
                       prop="nickname"></el-table-column>
      <el-table-column label="性别"
                       align="center"
                       prop="sex"></el-table-column>
      <el-table-column label="学号"
                       align="center"
                       prop="stuno"></el-table-column>
      <el-table-column label="班级"
                       align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.gname }}{{ scope.row.cname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="入学时间"
                       align="center"
                       prop="hiretime"></el-table-column>
      <el-table-column label="家长账号"
                       align="center"
                       prop="mobile"></el-table-column>
      <el-table-column label="状态"
                       align="center"
                       prop="statusStr"></el-table-column>
      <el-table-column label="操作"
                       align="center">
        <template slot-scope="scope">
          <el-button type="warning"
                     @click="detailClick(scope.row)">详 情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- endprint -->
    <el-dialog :visible.sync="addStudentFormVisible"
               width="730px"
               title="添加学生">
      <el-form :model="addStudentForm"
               ref="addStudentForm"
               label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名"
                          required
                          prop="name">
              <el-input v-model="addStudentForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入学时间"
                          required
                          prop="hiretime">
              <el-date-picker v-model="addStudentForm.hiretime"
                              value-format="yyyy-MM-dd"
                              style="width: 205px"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证"
                          required
                          prop="idCard">
              <el-input v-model="addStudentForm.idCard"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="性别"
                          required
                          prop="sex">
              <el-radio-group v-model="addStudentForm.sex">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家长姓名"
                          required
                          prop="parents">
              <el-input v-model="addStudentForm.parents"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家长手机号"
                          required
                          prop="mobile">
              <el-input v-model="addStudentForm.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家长姓名"
                          prop="parents1">
              <el-input v-model="addStudentForm.parents1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家长手机号"
                          prop="mobimobile1le">
              <el-input v-model="addStudentForm.mobile1"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="班级"
                          required
                          prop="classId">
              <el-cascader v-model="addStudentForm.classId"
                           :options="gradeList"></el-cascader>
            </el-form-item>
          </el-col>
          <div v-for="obstacle,index in addStudentForm.obstacleList"
               :key="index">
            <el-col :span="11">
              <el-form-item label="障碍类型"
                            prop="type">
                <el-select v-model="obstacle.type">
                  <el-option v-for="obstacle in obstacleList"
                             :key="obstacle.value"
                             :label="obstacle.name"
                             :value="obstacle.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="障碍程度"
                            prop="typeLevel">
                <el-select v-model="obstacle.typeLevel">
                  <el-option v-for="obstacleDegree in obstacleDegreeList"
                             :key="obstacleDegree.value"
                             :label="obstacleDegree.name"
                             :value="obstacleDegree.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <i class="el-icon-delete"
                 v-show="index!==0"
                 @click="deleteObstacle(index)"></i>
            </el-col>
          </div>
          <i class="el-icon-plus"
             @click="addObstacleList"></i>
          <el-col :span="24">
            <el-form-item label="家庭住址"
                          required
                          prop="homeAddress">
              <el-input v-model="addStudentForm.homeAddress"
                        class="address-input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="addStudentFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addConfirm">确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="detailVisible"
               width="750px">
      <el-tabs type="card">
        <el-tab-pane label="用户信息"
                     :disabled="addStudentForm.yearid!== nowSemester">
          <el-form :model="addStudentForm"
                   ref="studentFormDetail"
                   label-width="100px"
                   :disabled="addStudentForm.yearid!== nowSemester">
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名"
                              required
                              prop="name">
                  <el-input v-model="addStudentForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="入学时间"
                              required
                              prop="hiretime">
                  <el-date-picker v-model="addStudentForm.hiretime"
                                  value-format="yyyy-MM-dd"
                                  style="width: 205px"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证"
                              required
                              prop="idCard">
                  <el-input v-model="addStudentForm.idCard"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别"
                              required
                              prop="sex">
                  <el-radio-group v-model="addStudentForm.sex">
                    <el-radio label="男"></el-radio>
                    <el-radio label="女"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="家长姓名"
                              required
                              prop="parents">
                  <el-input v-model="addStudentForm.parents"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="家长手机号"
                              required
                              prop="mobile">
                  <el-input v-model="addStudentForm.mobile"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="家长姓名"
                              prop="parents1">
                  <el-input v-model="addStudentForm.parents1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="家长手机号"
                              prop="mobile1">
                  <el-input v-model="addStudentForm.mobile1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学号"
                              required
                              prop="studentNumber">
                  <el-input v-model="addStudentForm.studentNumber"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="班级"
                              required
                              prop="classId">
                  <el-cascader v-model="addStudentForm.classId"
                               :options="gradeList"></el-cascader>
                </el-form-item>
              </el-col>
              <div v-for="obstacle,index in addStudentForm.obstacleList"
                   :key="index">
                <el-col :span="11">
                  <el-form-item label="障碍类型"
                                prop="type">
                    <el-select v-model="obstacle.type">
                      <el-option v-for="obstacle in obstacleList"
                                 :key="obstacle.value"
                                 :label="obstacle.name"
                                 :value="obstacle.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="障碍程度"
                                prop="typeLevel">
                    <el-select v-model="obstacle.typeLevel">
                      <el-option v-for="obstacleDegree in obstacleDegreeList"
                                 :key="obstacleDegree.value"
                                 :label="obstacleDegree.name"
                                 :value="obstacleDegree.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <i class="el-icon-delete"
                     v-show="index!==0"
                     @click="deleteObstacle(index)"></i>
                </el-col>
              </div>
              <i class="el-icon-plus"
                 @click="addObstacleList"></i>
              <el-col :span="24">
                <el-form-item label="家庭住址"
                              required
                              prop="homeAddress">
                  <el-input v-model="addStudentForm.homeAddress"
                            class="address-input"></el-input>
                </el-form-item>
              </el-col>

            </el-row>
          </el-form>
          <el-button :disabled="addStudentForm.yearid !== nowSemester"
                     type="primary"
                     class="modify-button"
                     @click="modifyStudentForm">修改
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="重置密码"
                     :disabled="addStudentForm.yearid !== nowSemester">
          <div class="resetPwd-box">
            <el-form :model="pwdForm"
                     :rules="pwdFormRules"
                     ref="pwdForm"
                     label-width="80px">
              <el-form-item label="密码"
                            prop="pwd">
                <el-input v-model="pwdForm.pwd"
                          type="password"></el-input>
              </el-form-item>
              <el-form-item label="确认密码"
                            prop="repeatPwd">
                <el-input v-model="pwdForm.repeatPwd"
                          type="password"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-button type="warning"
                     @click="resetPwd"
                     class="modify-button">密码重置
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="删除用户"
                     :disabled="addStudentForm.yearid !== nowSemester">
          此操作将删除用户及用户所有信息
          <el-button type="danger"
                     class="delete-button"
                     @click="deleteClick">删除用户
          </el-button>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadAllStudent"></el-pagination>
  </div>
</template>

<script>
let id = 0;
export default {
  name: "studentManage",
  data() {
    return {
      nowSemester: Number(sessionStorage.getItem("nowSemester")),
      semesterList: JSON.parse(sessionStorage.getItem("semesterList")),
      gradeList: [],
      termList: [],
      classList: [],
      termId: "",
      gradeId: "",
      classId: "",
      queryStudent: "",
      page: { currentPage: 0, pageCount: 1 },
      studentTable: [],
      addStudentFormVisible: false,
      detailVisible: false,
      addStudentForm: {
        name: "",
        hiretime: "",
        mobile: "",
        obstacleList: [{ type: "", typeLevel: "" }],
        sex: "",
        classId: "",
        parentName: "",
        idCard: "",
        homeAddress: "",
        yearid: "",
        id: "",
        studentNumber: "",
        mobile1: "",
        parents: "",
        parents1: "",
      },
      selectedStudentList: [],
      obstacleList: [
        {
          name: "视力残疾",
          value: 1,
        },
        {
          name: "听力残疾",
          value: 2,
        },
        {
          name: "言语残疾",
          value: 3,
        },
        {
          name: "精神残疾",
          value: 4,
        },
        {
          name: "智力残疾",
          value: 5,
        },
        {
          name: "肢体残疾",
          value: 6,
        },
        {
          name: "多重残疾",
          value: 7,
        },
      ],
      obstacleDegreeList: [
        {
          name: "一级",
          value: 1,
        },
        {
          name: "二级",
          value: 2,
        },
        {
          name: "三级",
          value: 3,
        },
        {
          name: "四级",
          value: 4,
        },
      ],
      pwdForm: {
        pwd: "",
        repeatPwd: "",
      },
      pwdFormRules: {
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        repeatPwd: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          setTimeout(() => {
            const nodes = Array.from({ length: level + 1 }).map((item) => ({
              value: ++id,
              label: `选项${id}`,
              leaf: level >= 2,
            }));
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }, 1000);
        },
      },
      moduleManagementList: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      roleType: false, //是否为模块管理员
    };
  },
  created() {
    //105个别化教育资料页面
    for (let j = 0; j < this.moduleManagementList.length; j++) {
      if (this.moduleManagementList[j].id === 105) {
        this.roleType = true;
      }
    }
    if (this.semesterList[0]) {
      this.termId = this.semesterList[0].id;
    }
    this.loadAllStudent(1);
    this.loadAllClass(this.termId);
  },
  methods: {
    loadAllClass(yearId) {
      const that = this;
      this.$post("/media/queryClassesByYear.do", {
        yearid: yearId,
        type: this.roleType ? 1 : 2,
      })
        .then((res) => {
          let gradeList = [];
          res.data.forEach((grade) => {
            let children = [];
            grade.classlist.forEach((cls) => {
              children.push({ value: cls.id, label: cls.cname });
            });
            gradeList.push({ value: grade.id, label: grade.gname, children });
          });
          that.gradeList = gradeList;
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    loadAllStudent(page, type = 0) {
      if (type === 1) {
        this.classList = [];
        this.gradeId = "";
        this.classId = "";
      }
      if (type === 2) {
        this.classId = "";
      }
      const that = this;
      let data = { indexNo: page };
      if (this.queryStudent) {
        data.name = this.queryStudent;
      }
      if (this.termId) {
        data.yearid = this.termId;
      }
      if (this.gradeId) {
        data.gradeid = this.gradeId;
      }
      if (this.classId) {
        data.classid = this.classId;
      }
      this.$post("/media/queryStudent.do", data)
        .then((res) => {
          that.page.currentPage = page;
          that.page.pageCount = res.data.pageCount;
          that.studentTable = res.data.rows;
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.loadAllStudent(1);
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    //导出还没有接口
    exportFile() {
      const that = this;
      this.$postFile("", {})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "学生导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    studentSelectChange(selectedStudentList) {
      this.selectedStudentList = [];
      selectedStudentList.forEach((selectedStudent) => {
        this.selectedStudentList.push(selectedStudent.id);
      });
    },
    addConfirm() {
      const that = this;
      this.$refs["addStudentForm"].validate((valid) => {
        if (valid) {
          let data = {
            nickname: this.addStudentForm.name,
            sex: this.addStudentForm.sex,
            mobile: this.addStudentForm.mobile,
            mobile1: this.addStudentForm.mobile1,
            parents: this.addStudentForm.parents,
            parents1: this.addStudentForm.parents1,
            classid: this.addStudentForm.classId[1],
            gradeid: this.addStudentForm.classId[0],
            hiretime: this.addStudentForm.hiretime,
            // type: this.addStudentForm.obstacle,
            // type_level: this.addStudentForm.obstacleDegree,
            idcard: this.addStudentForm.idCard,
            address: this.addStudentForm.homeAddress,
            studentType: this.addStudentForm.obstacleList,
          };
          this.$confirm("是否确认添加该学生", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$postJson("/media/insertStudent.do", data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: "添加成功",
                  });
                  that.addStudentFormVisible = false;
                  that.loadAllStudent(1);
                })
                .catch((error) => {
                  that.addStudentFormVisible = false;
                  that.$message.error(error.message);
                });
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                type: "info",
                message: "已取消添加",
              });
            });
        }
      });
    },
    termClick(id) {
      this.termId = id;
      this.classList = [];
      this.gradeId = "";
      this.classId = "";
      this.loadAllClass(id);
      this.loadAllStudent(1);
    },
    gradeClick(grade) {
      this.classList = grade.children;
      this.gradeId = grade.value;
      this.classId = "";
      this.loadAllStudent(1);
    },
    classClick(id) {
      this.classId = id;
      this.loadAllStudent(1);
    },
    detailClick(student) {
      this.detailVisible = true;
      this.$nextTick(() => {
        this.addStudentForm.name = student.nickname;
        this.addStudentForm.hiretime = student.hiretime;
        this.addStudentForm.mobile = student.mobile;
        this.addStudentForm.mobile1 = student.mobile1;
        this.addStudentForm.parents = student.parents;
        this.addStudentForm.parents1 = student.parents1;
        // this.addStudentForm.obstacle = student.type;
        this.addStudentForm.sex = student.sex;
        this.addStudentForm.classId = student.classid;
        this.addStudentForm.parentName = student.username;
        this.addStudentForm.idCard = student.idcard;
        this.addStudentForm.homeAddress = student.address;
        // this.addStudentForm.obstacleDegree = student.type_level;
        this.addStudentForm.yearid = student.yearid;
        this.addStudentForm.studentNumber = student.stuno;
        this.addStudentForm.id = student.id;
        this.addStudentForm.obstacleList = student.studentType;
      });
    },
    resetPwd() {
      const that = this;
      if (this.pwdForm.pwd === this.pwdForm.repeatPwd) {
        this.$confirm("是否确认重置密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$post("/media/updateStudentPassword.do", {
              stuno: this.addStudentForm.studentNumber,
              password: this.pwdForm.pwd,
              mobile: this.addStudentForm.mobile,
            })
              .then((res) => {
                that.$message({
                  type: "success",
                  message: "重置成功!",
                });
              })
              .catch((error) => {
                that.$message.error(error.message);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消重置",
            });
          });
      } else {
        this.$message({
          type: "warning",
          message: "密码没改",
        });
      }
    },
    addStudentFormClick() {
      this.addStudentFormVisible = true;
      this.$nextTick(() => {
        this.$refs["addStudentForm"].resetFields();
        if (this.$refs["studentFormDetail"]) {
          this.$refs["studentFormDetail"].resetFields();
        }
        this.addStudentForm.id = "";
        this.addStudentForm.yearid = "";
        this.addStudentForm.obstacleList = [{ type: "", typeLevel: "" }];
      });
    },
    modifyStudentForm() {
      const that = this;
      this.$refs["studentFormDetail"].validate((valid) => {
        if (valid) {
          let data = {
            id: this.addStudentForm.id,
            nickname: this.addStudentForm.name,
            sex: this.addStudentForm.sex,
            mobile: this.addStudentForm.mobile,
            mobile1: this.addStudentForm.mobile1,
            parents: this.addStudentForm.parents,
            parents1: this.addStudentForm.parents1,
            username: this.addStudentForm.parentName,
            stuno: this.addStudentForm.studentNumber,
            yearid: this.addStudentForm.yearid,
            status: this.addStudentForm.status,
            // type: this.addStudentForm.obstacle,
            // type_level: this.addStudentForm.obstacleDegree,
            idcard: this.addStudentForm.idCard,
            address: this.addStudentForm.homeAddress,
            classid: this.addStudentForm.classId[1],
            gradeid: this.addStudentForm.classId[0],
            studentType: this.addStudentForm.obstacleList,
          };
          this.$confirm("是否修改用户信息", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$postJson("/media/updateStudent.do", data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: "修改成功",
                  });
                  that.detailVisible = false;
                  this.loadAllStudent(1);
                })
                .catch((error) => {
                  that.$message.error(error.message);
                  that.detailVisible = false;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消修改",
              });
            });
        }
      });
    },
    deleteClick() {
      const that = this;
      this.$confirm("是否确认删除该用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteStudent.do", {
            ids: this.addStudentForm.id,
          })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.detailVisible = false;
              that.loadAllStudent(1);
            })
            .catch((error) => {
              that.$message.error(error.message);
              that.detailVisible = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchDelete() {
      const that = this;
      this.$confirm("是否确认删除这些学生", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteStudent.do", {
            ids: this.selectedStudentList.join(","),
          })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadAllStudent(1);
            })
            .catch((error) => {
              that.$message.error(error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    downLoadStudentTemplate() {
      const that = this;
      this.$postFile("/media/downloadStutemplate.do", {})
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "学生导入模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    addObstacleList() {
      let newObject = { type: "", type_level: "" };
      if (!this.addStudentForm.obstacleList) {
        this.addStudentForm.obstacleList = [{ type: "", type_level: "" }];
      } else {
        this.addStudentForm.obstacleList.push(newObject);
      }
    },
    deleteObstacle(index) {
      this.addStudentForm.obstacleList.splice(index, 1);
    },
  },
};
</script>

<style>
</style>

<style scoped lang="scss">
.button-box {
  display: flex;
  justify-content: space-between;
}

.input-box {
  width: 200px;
  margin-right: 5px;
}

.link-box {
  margin-left: 20px;
  font-size: 18px;
  line-height: 45px;
}

.modify-button {
  margin-left: 535px;
}

.resetPwd-box {
  width: 350px;
  margin: 0 auto;
}

.select-box {
  font-size: 18px;
  margin-bottom: 20px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/deep/ .el-upload {
  margin: 0 10px;
}
.address-input {
  width: 100% !important;
}
.el-icon-delete {
  font-size: 20px;
  padding: 10px 5px;
}
.el-icon-plus {
  margin: 10px 6px;
  font-size: 20px;
}
</style>
